<template lang="pug">
div
  v-tabs(v-model="activeTab")
    v-tabs-slider(color="primary")
    div.d-flex.align-items-center(v-for="tab in tabs" :key="tab.id")
      v-tab(:to="tab.link" v-if="tab.checkAccess()").p-2 {{$t(tab.label)}}
  router-view
</template>

<script>
import { checkAccess } from '@/mixins/permissions'
export default {
  data () {
    return {
      activeTab: null,
      tabs: [
        { id: 1, label: 'Distribution-OLD', link: { name: 'distribution-usual-report-crewing' }, checkAccess: () => checkAccess('admin') },
        { id: 2, label: 'distributionNostrificationMES', link: { name: 'distribution-nostrification', params: { typeDocument: 'mes' } }, checkAccess: () => checkAccess('admin') },
        { id: 3, label: 'Distribution-NEW', link: { name: 'distribution-usual', params: { typeDocument: 'sqc' } }, checkAccess: () => checkAccess('admin') }
      ]
    }
  }
}
</script>
